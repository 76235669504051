import React, { useEffect, useState } from "react";
import { Badge, Card, Col, Dropdown, Table } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import Spinner from "../components/common/Spinner";
import {
  getInteriorList,
  resendTempPassword,
  userAction,
} from "../../services/InteriorDesigner/InteriorDesignerService";
import moment from "moment";
import Pagination from "../components/common/Pagination";
import { toast, ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import AddInteriorD from "../components/Modal/AddInteriorD";
import "./interiorDesignerManagement.css";

export default function InteriorDesignerManagement(props) {
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  const notifyTopRight = (success) => {
    toast.success(`✅ ${success}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const notifyError = (error) => {
    toast.error(`❌${error}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [apiError, setApiError] = useState("");
  const [search, setSearch] = useState("");
  const limit = 10;
  const [listLength, setListlenght] = useState("");
  const [addModal, setAddModal] = useState(false);

  // console.log(search," search")
  function getTableData() {
    setLoader(true);
    getInteriorList(currentPage, limit, search)
      .then((response) => {
        setUsers(response.data.data.users);
        const total = response.data.data.total;
        setLoader(false);
        setPageCount(Math.ceil(total / limit));
        setListlenght(response.data.data.total);
        console.log(response.data, " table data ");
      })
      .catch((error) => {
        console.log(error.response, "helooooooooo");
        if (error?.response?.data?.statusCode === 401) {
          localStorage.removeItem("taldDetails");
          props.history.push("/login");
        }
      });
  }

  function onAction(userId, key, isBlocked) {
    setLoader(true);
    userAction(userId, key)
      .then((response) => {
        console.log(response, "api response");
        if (key === "isBlocked") {
          if (isBlocked) {
            notifyTopRight("Blocked Successfully!");
          } else {
            notifyTopRight("Unblocked Successfully!");
          }
        } else {
          notifyTopRight("Approved Successfully!");
        }
        setLoader(false);
        getTableData();
      })
      .catch((error) => {
        setLoader(false);
        notifyError(error.response?.data?.message);
        console.log(error.response, "helooooooooo");
        if (error.response?.data?.statusCode === 401) {
          localStorage.removeItem("taldDetails");
          props.history.push("/");
        }
      });
  }

  async function handleResendTempPassword(email) {
    try {
      await resendTempPassword(email);
      toast.success("Successfully sent the temp password");
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }

  async function handleLoginAsDesigner(designerEmail) {
    const adminAuthToken = localStorage.getItem("taldDetails");

    if (!adminAuthToken) {
      toast.error("Something went wrong");
      console.error(`Didn't find admin auth token.`);
    }

    const url = new URL(
      (process.env.REACT_APP_DESIGNER_PORTAL_URL ||
        "https://designer.tald.co") + "/auth/admin-login"
    );

    url.searchParams.append("adminAuthToken", adminAuthToken);
    url.searchParams.append("designerEmail", designerEmail);

    window?.open(url.href, "_blank").focus();
  }

  useEffect(() => {
    getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, search]);

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <PageTitle activeMenu="Member Accounts List" motherMenu="Member Accounts" />
      <Col>
        <Card>
          <Card.Header className="">
            <div className="row d-flex justify-content-end ">
              <div className="col-4" style={{ flexGrow: 1 }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div className="input-group border bg-white input-group-sm" style={{ borderRadius: "8px" }}>
                    <input
                      style={{
                        paddingBottom: "25px",
                        paddingTop: "25px",
                        borderRadius: "10px",
                        fontSize: "14px",
                      }}
                      type="text"
                      name="table_search"
                      className="form-control float-right border-0"
                      placeholder="Search"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn btn-default"
                        // onClick={getTableData}
                      >
                        <i className="fa fa-search" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mr-2">
                <Link
                  variant="primary"
                  type="button"
                  className="btn btn-color px-2 py-2 text-white"
                  onClick={() => setAddModal(true)}
                >
                  Add New +
                </Link>
              </div>
            </div>
          </Card.Header>
          <Card.Body className="interior-designer-management-table">
            <Table responsive>
              <thead style={{ color: "black" }}>
                <tr>
                  <th>
                    <strong>NAME</strong>
                  </th>
                  <th>
                    <strong>EMAIL</strong>
                  </th>
                  <th>
                    <strong>CREATED AT</strong>
                  </th>
                  <th>
                    <strong>ACTIVITY STATUS</strong>
                  </th>
                  <th>
                    <strong>PROFILE STATUS</strong>
                  </th>
                  <th>
                    <strong>ACTION</strong>
                  </th>
                </tr>
              </thead>
              <tbody className="border-bottom border-secondary">
                {users?.map((item, index) => (
                  <tr key={index}>
                    <td
                      onClick={() => {
                        props.history.push(`/interior-designer-management/${item._id}`);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {item.name}
                    </td>
                    <td>{item.email}</td>
                    <td>{moment(item.createdAt).format("MM/DD/YYYY")}</td>
                    <td>
                      {item.isBlocked ? (
                        <Badge variant="danger light ">Inactive</Badge>
                      ) : (
                        <Badge variant="success light ">Active</Badge>
                      )}
                    </td>
                    <td>
                      {item.isApproved ? (
                        <Badge variant="success light ">Approved</Badge>
                      ) : (
                        <Badge variant="danger light ">Pending Approval</Badge>
                      )}
                    </td>
                    <td className="z-index-3">
                      <Dropdown>
                        <Dropdown.Toggle variant="secondary light" className="light sharp btn btn-secondary i-false">
                          {svg1}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {item.isBlocked ? (
                            <Dropdown.Item onClick={() => onAction(item._id, "isBlocked", false)}>Enable</Dropdown.Item>
                          ) : (
                            <Dropdown.Item onClick={() => onAction(item._id, "isBlocked", true)}>Disable</Dropdown.Item>
                          )}
                          {!item.isApproved && (
                            <Dropdown.Item onClick={() => onAction(item._id, "isApproved", true)}>
                              Approve
                            </Dropdown.Item>
                          )}
                          {!item?.isSubscription && <Dropdown.Item
                            onClick={() =>
                              handleResendTempPassword(item.email)
                            }
                          >
                            Resend Temp Password
                          </Dropdown.Item>}
                          <Dropdown.Item
                            onClick={() => handleLoginAsDesigner(item.email)}
                          >
                            Login as Designer
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {users?.length === 0 && !loader ? (
              <div className="justify-content-center d-flex my-5 ">Sorry, Data Not Found!</div>
            ) : (
              ""
            )}
            {users?.length === 0 ? (
              ""
            ) : (
              <div className="">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="dataTables_info">
                    Showing {currentPage * limit + 1} to{" "}
                    {listLength > (currentPage + 1) * limit ? (currentPage + 1) * limit : listLength} of {listLength}{" "}
                    entries
                  </div>
                  <Pagination pageCount={pageCount} pageValue={currentPage} setPage={setCurrentPage} />
                </div>
              </div>
            )}
          </Card.Body>
        </Card>
      </Col>
      <AddInteriorD show={addModal} table={getTableData} onHide={() => setAddModal(false)} />
      {loader && <Spinner />}
    </div>
  );
}
