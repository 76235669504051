import React, { useEffect, useState } from 'react';
import axios from 'axios'
import { useHistory } from 'react-router-dom';
import {
    exchangeCodeForToken,
    pinterestTokenValidation
} from "../../services/AuthService";

function Integrations() {
    const [authCode, setAuthCode] = useState('');
    const [tokenValidated, setTokenValidated] = useState(false)

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        if (code) {
            console.log('Authorization code:', code);
            setAuthCode(code);
            window.history.pushState({}, document.title, window.location.pathname);
        } else {
            pinterestTokenValidation()
                .then((data) => {
                    const isValidated = data?.data?.data
                    setTokenValidated(isValidated)
                }).catch(err => console.log(err))
        }
    }, []);


    useEffect(() => {
        if (!authCode) return
        exchangeCodeForToken(authCode)
            .then(data => setTokenValidated(true))
            .catch(err => console.log(err))
    }, [authCode])

    const redirectToPinterest = () => {
        console.log('Redirecting to Pinterest...');
        const baseUrl = `https://www.pinterest.com/oauth/?client_id=${process.env.REACT_APP_PINTEREST_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_PINTEREST_REDIRECT_URL}&response_type=code&scope=boards:read,pins:read,boards:write,pins:write`;
        window.location.href = baseUrl;
    };


    return (
        <div style={{ border: '1px solid #ccc', borderRadius: '5px', padding: '20px', margin: '20px', width: 'max-content' }}>
            <div style={{ fontSize: 'larger', fontWeight: 'bold', marginBottom: '15px' }}>{tokenValidated ? `Pinterest Integration Successfull` : 'Connect Pinterest Here'}</div>
            {tokenValidated ? (
                <div style={{ color: 'green', fontSize: '24px' }}>
                    ✔︎
                </div>
            ) : (
                <button style={{ padding: '10px 20px', cursor: 'pointer' }} onClick={redirectToPinterest}>
                    Validate
                </button>
            )}
        </div>
    );
}

export default Integrations;